
.headline-text-terms {
    font-size:24px;
}

.terms-tab-1 {
    margin-left:2.5% !important;
    width:95% !important;
}

.terms-tab-2 {
    margin-left:5% !important;
    width:92.5% !important;
}

.terms-tab-3 {
    margin-left:7.5% !important;
    width:90% !important;
}