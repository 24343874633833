html body {
  background-color: black;
}
.App {
  position:relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

.stacked {
  justify-content: flex-start;
}
