.race-header-main {
    position:relative;
    z-index:100;
    padding:25px !important;
    padding-bottom:20px !important;
}

.race-header-title {
    font-size:24px;
    font-weight:900;
    color:white;
    margin-top:-5px;
}

.race-header-top-details {
    font-size:16px;
    margin-right:20px;
}

.race-header-top-details-text {
    margin-top:1px;
    margin-left:5px;
}

.race-header-top-details-icon-time {
    font-size:25px;
    margin-right:5px;
    margin-top:1px;
}

.race-header-top-details-icon {
    height:25px;
    margin-top:-1px;
    margin-left:10px;
}

.racenavtoggle {
    background:transparent;
    color: white !important;
    margin-right:10px;
    border-radius:5px;
}

.racenavtoggle:hover {
    background:#FF0000 !important;
}

.racenavtoggleactive {
    background:#FF0000 !important;
    color:white !important;
    margin-right:10px;
    border-radius:5px;
}

.race-header-right-action-section {
    width:auto;
    margin-right:10px;
}

.racesectionmainbutton {
    background-color:#d0cfce !important;
    color:black !important;
}

.racesectionmainbutton:hover {
    background:#FF0000 !important;
    color:white !important;
}