.accordion {
    color: var(--light-gray-text);
}

.accordion-header {
  background-color: gray;
  line-height: 1;
  margin: 0;
  font-size: 16px;
  padding: 14px;
  padding-top: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  color: white;
  font-family: "EurostileExt","Helvetica","Arial";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
  background-color: gray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 16px;
  transform: translateY(-5px);
}

.accordion-content.accordion-open {
  max-height: 500px;
  transition: max-height 0.4s ease;

}