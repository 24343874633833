
.stats-viewer-primary-icon {
    line-height:1;
}

.stats-viewer-headline {
    font-size:32px;
}

.stats-viewer-subheadline {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size:12px;
    margin-top:5px;
}

.stats-viewer-stats-headline {
    margin-top:30px;
    font-size:18px;
    margin-bottom:5px;
}

.workshopsection {
    padding:20px;
}

.leftnavtoggle:hover {
    color:#EE1E26;
}

.topnavtoggle {
    color: white;
}
.topnavtoggle:hover {
    color:#EE1E26;
}

.btn-flat2 {
    font-family:'EurostileExt','Helvetica','Arial';
    font-size:18px;
}

.postrace {
    display:none;
}

.racecardbox {
    margin:5%;
    width:90%;
    background-color:#1E272D;
    border-radius:10px;
    overflow:hidden;
    opacity:.90;
    border: 3px solid white;
}

.predictionbox {
    margin:5%;
    width:90%;
    background-color:#1E272D;
    border-radius:10px;
    overflow:hidden;
    opacity:.90;
    border: 3px solid white;
}

.workshopsectionbox {
    width:90%;
    background-color:#1E272D;
    overflow:hidden;
    opacity:.90;
    border-radius:10px;
    border: 0px solid white;
    /* box-shadow: 0px 0px 12px rgba(0,255,255,0.5);
    border: 1px solid rgba(127,255,255,0.25);

    background:rgba(0,127,127,.25); */
}

.itembox {
    position:relative;
    margin:5%;
    width:90%;
    border-radius:2px;
    overflow:hidden;
    border: 2px solid #EBEBEB;
}

.itembox:hover {
    border-color: #EE1E26;
    cursor:pointer;
}

.itemboxselected {
    border-color: #EE1E26;
}

.predictionbox:hover {
    background-color: #EE1E26;
}

.predictionboxselected {
    background-color: #EE1E26 !important;
}

.itemtriangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent white transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
}

.itemstar {
    right: 1;
    top: 1;
    position: absolute;
}

.itemtriangleselected {
    border-color: transparent #EE1E26 transparent transparent;
}

.itembottombar {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding-top:5px;
    text-align:center;
    font-size:10px;
    background:white;
    color:black;
}

.itembigcount {
    position:absolute;
    top:30%;
    width:100%;
    text-align:center;
    font-size:24px;
    color:white;
}

.workshop-nav-header {
    width:120%;text-align:center;font-size:18px;margin-left:-10%;padding-top:15px;padding-bottom:10px;
}

.workshop-nav-section {
    width:100%;margin-top:20px;margin-bottom:20px;
}

.workshop-nav-border-button {
    margin-left:5%;width:90%;margin-top:70px;padding:5px 10px;padding-top:5px;margin-bottom:30px;border:1px solid #EE1E26;text-align:center;
}

.workshop-nav-chevron {
    float:right;font-size:30px;
}

.workshop-nav-button-text {
    float:right;margin-top:0px;font-size:16px;
}
.entry-carousel-wide {
    height:450px !important;
}
.entry-selection-box-wide {
    border:1px solid white;
    height:350px;
    width:100%;
    background: rgb(0,0,0,.3);
    border-radius:10px;
    overflow:hidden;
}

.entry-selection-top-wide {
    height:350px !important;
    width:100%;
    border-right:1px solid white;
}

.entry-selection-bottom-wide {
    border-top:1px solid white;
    height:350px !important;
    width:100%;
}

/* RACE BOARD */
.race-board-main {
    width:100%;
    position:relative;
    z-index:100;
    color:white;
    margin-top:0px;
}
.race-board-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom:10px;
    border-bottom:1px solid white;
    cursor:pointer;
}

.race-board-row-launch-icon {
    font-size:18px;
    margin-left:10px;
    margin-top:1px;
}

.race-board-prize-text {
    float:right;
    margin-top:1px;
    margin-left:2px;
}

.race-board-prize-icon {
    float:right;
    height:25px;
    margin-top:-1px;
    margin-left:10px;
}

.race-board-prize-icon2 {
    float:right;
    height:24px;
    margin-top:-1px;
    filter: grayscale(1);
}

.race-board-time-icon {
    font-size:18px;
    margin-right:5px;
    margin-top:1px;
}


.exr-nviewer-segment {
    background-color:#3A3A3A;
    border-radius:10px;
    padding:20px;
}

.exr-nviewer-divider {
    border:1px solid white;
    width:100%;
    height:1px;
    margin-top:10px;
    margin-bottom:10px;
}

.exr-nviewer-img-large {
    width:100%;
    overflow:hidden;
    background-size:cover !important;
    background-image:'';
    background-position:center;
}

.exr-nviewer-main-faction-icon {
    position:absolute;
    bottom:10px;
    right:10px;
}

.exr-nviewer-main-faction-icon-static {
    margin-top:-15px;
    margin-left:-5px;
    line-height: .6;
}

.exr-nviewer-main-headline {
    font-size:30px;
    width:100%;
    margin-top:15px;
    line-height: 1;
}

.exr-nviewer-main-subheadline {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size:14px;
    margin-top:5px;
}

.exr-nviewer-section-headline {
    font-size:20px;
    width:100%;
    margin-top:20px;

}

.exr-nviewer-main-text {
    font-size:16px;
    width:100%;
}

.divider-thin {
    height:1px;
    width:100%;
    background:white;
  }

.exr-selected-grid-box {
    opacity:1 !important;
    cursor:zoom-in !important;
    border-radius:10px;
}

.exr-filled-grid-box {
    opacity:0.25;
    cursor:pointer;
    border-radius:10px;
}

.exr-filled-grid-box:hover {
    opacity:1;
    border-radius:10px;
}

.nviewer-info-carousel-section {
    display:none;
}

.nviewer-attribute-bubble {
    border:1px solid white;
    padding:2px;
    font-size:14px;
    text-align:center;
    margin-bottom:5px;
    border-radius:5px;
}

.nviewer-rarity-num-large {
    font-size:20px;
    padding:2px 10px;
    border-radius:5px;
    border:0px solid white;
    text-align:center;
    margin-right:20px;
}

.nviewer-info-carousel-button {
    float:right;
    background-color:white;
    height:10px;
    border-radius:10px;
    width:10px;
    cursor:pointer;
    margin-right:10px;
  }
  
  .nviewer-info-carousel-button:hover{
    background-color:#FF0000;
  }
  
  .nviewer-info-carousel-button-white:hover{
    background-color:white;
  }
  
  .info-carousel-button-filled-white {
    background-color:white;
  }
  
  .info-carousel-button-filled {
    background-color:#FF0000;
  }

#exr-nviewer-info-box {
    /* overflow-x:hidden;*/
    scrollbar-color:#3A3A3A #272727; 
}

#exr-nviewer-grid-box {
    background:#272727;
    overflow-x:hidden;
    scrollbar-color:#3A3A3A transparent;
}

/* .body-main::-webkit-scrollbar-track { 
    display:none;
} */

.body-main::-webkit-scrollbar-thumb { 
    background:#3A3A3A;
    border-radius:10px;
}

.simplebar-scrollbar::before {
    background-color:transparent;
}
.simplebar-track {
    background-color:transparent;
}
.simplebar-scrollbar {
    background-color:transparent;
}

.select-wrapper {
    border:1px solid #606060 !important;
    border-radius:5px !important;
    box-shadow: none;
    background:transparent;
}

.select-wrapper input {
    border:0px solid #606060 !important;
    border-radius:5px !important;
    box-shadow: none;
    background:transparent;
}
