
.stats-viewer-primary-icon {
    line-height:1;
}

.stats-viewer-headline {
    font-size:32px;
}

.stats-viewer-subheadline {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size:12px;
    margin-top:5px;
}

.stats-viewer-stats-headline {
    margin-top:30px;
    font-size:18px;
    margin-bottom:5px;
}

.workshopsection {
    padding:20px;
}

.leftnavtoggle:hover {
    color:#EE1E26;
}

.topnavtoggle {
    color: white;
}
.topnavtoggle:hover {
    color:#EE1E26;
}

.btn-flat2 {
    font-family:'EurostileExt','Helvetica','Arial';
    font-size:18px;
}

.postrace {
    display:none;
}

.racecardbox {
    margin:5%;
    width:90%;
    background-color:#1E272D;
    border-radius:10px;
    overflow:hidden;
    opacity:.90;
    border: 3px solid white;
}

.predictionbox {
    margin:5%;
    width:90%;
    background-color:#1E272D;
    border-radius:10px;
    overflow:hidden;
    opacity:.90;
    border: 3px solid white;
}

.workshopsectionbox {
    width:90%;
    background-color:#1E272D;
    overflow:hidden;
    opacity:.90;
    border-radius:10px;
    border: 0px solid white;
    /* box-shadow: 0px 0px 12px rgba(0,255,255,0.5);
    border: 1px solid rgba(127,255,255,0.25);

    background:rgba(0,127,127,.25); */
}

.itembox {
    position:relative;
    margin:5%;
    width:90%;
    border-radius:2px;
    overflow:hidden;
    border: 2px solid #EBEBEB;
}

.itembox:hover {
    border-color: #EE1E26;
    cursor:pointer;
}

.itemboxselected {
    border-color: #EE1E26;
}

.predictionbox:hover {
    background-color: #EE1E26;
}

.predictionboxselected {
    background-color: #EE1E26 !important;
}

.itemtriangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent white transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
}

.itemstar {
    right: 1;
    top: 1;
    position: absolute;
}

.itemtriangleselected {
    border-color: transparent #EE1E26 transparent transparent;
}

.itembottombar {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding-top:5px;
    text-align:center;
    font-size:10px;
    background:white;
    color:black;
}

.itembigcount {
    position:absolute;
    top:30%;
    width:100%;
    text-align:center;
    font-size:24px;
    color:white;
}

.workshop-nav-header {
    width:120%;text-align:center;font-size:18px;margin-left:-10%;padding-top:15px;padding-bottom:10px;
}

.workshop-nav-section {
    width:100%;margin-top:20px;margin-bottom:20px;
}

.workshop-nav-border-button {
    margin-left:5%;width:90%;margin-top:70px;padding:5px 10px;padding-top:5px;margin-bottom:30px;border:1px solid #EE1E26;text-align:center;
}

.workshop-nav-chevron {
    float:right;font-size:30px;
}

.workshop-nav-button-text {
    float:right;margin-top:0px;font-size:16px;
}
.entry-carousel-wide {
    height:450px !important;
}
.entry-selection-box-wide {
    border:1px solid white;
    height:350px;
    width:100%;
    background: rgb(0,0,0,.3);
    border-radius:10px;
    overflow:hidden;
}

.entry-selection-top-wide {
    height:350px !important;
    width:100%;
    border-right:1px solid white;
}

.entry-selection-bottom-wide {
    border-top:1px solid white;
    height:350px !important;
    width:100%;
}

/* RACE BOARD */
.race-board-main {
    width:100%;
    position:relative;
    z-index:100;
    color:white;
    margin-top:0px;
}
.race-board-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom:10px;
    border-bottom:1px solid white;
    cursor:pointer;
}

.race-board-row-launch-icon {
    font-size:18px;
    margin-left:10px;
    margin-top:1px;
}

.race-board-prize-text {
    float:right;
    margin-top:1px;
    margin-left:2px;
}

.race-board-prize-icon {
    float:right;
    height:25px;
    margin-top:-1px;
    margin-left:10px;
}

.race-board-prize-icon2 {
    float:right;
    height:24px;
    margin-top:-1px;
    filter: grayscale(1);
}

.race-board-time-icon {
    font-size:18px;
    margin-right:5px;
    margin-top:1px;
}


#track-viewer {
    overflow:hidden;
    width: 100%;
    height: 400px;
}

#track-viewer svg {
    width: 100%;
    height: 100%;
    --color-minimap-track: white;

}

#track-viewer .track {
    fill: var(--color-minimap-track);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));
}

#track-viewer .finish {
    stroke: var(--color-minimap-finish);
    stroke-width: 2;
    stroke-dasharray: 2;
}

#track-viewer .special {
    stroke: var(--color-minimap-highlight);
    stroke-width: 1;
}

.stack-selection-box {
    margin:0px;
    height:600px;
    border-radius:10px;
    margin-bottom:15px;
}

.stack-selection-col {
    width:100%;
    position:relative;
}

.stack-selection-caro-left-button {
    cursor:pointer;
    position:absolute;
    bottom:12px;
    left:0px;
    font-size:50px;
    z-index:100;
}

.stack-selection-caro-right-button {
    cursor:pointer;
    position:absolute;
    bottom:12px;
    right:0px;
    font-size:50px;
    z-index:100;
}

.stack-selection-caro {
    height:600px !important;
}

.stack-selection-caro-container {
    position:relative;
    height:100%;
    min-height:100% !important;
    font-size:14px;
}

.stack-selection-caro-box {
    width:100%;
    height:100%;
    position:relative;
}

.stack-selection-caro-image-box {
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    border-radius:10px;
}

.stack-selection-caro-text-box {
    width:98%;
    margin:1%;
    padding-top:20px;
    position:absolute;
    bottom:0px;
    left:0px;
    border-radius:10px;
    color:white;
}

.stack-selection-caro-icon {
    position:absolute;
    top:0px;
    right:5px;
    line-height:1;
}

.final-results-col {
    margin-bottom:10px;
    padding:10px !important;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    border:0px solid white;
    font-family:helvetica;
    overflow:hidden;
}

.predict-hover {
    border:2px solid #606060;
}

.predict-hover:hover{
    border:2px solid white;
}

.select-wrapper {
    border:1px solid #606060 !important;
    border-radius:5px !important;
    box-shadow: none;
    background:transparent;
}

.dropdown-trigger {
    padding-bottom:0px !important;
    margin-bottom:0px !important;
}

.select-dropdown {
    padding-bottom:0px !important;
    margin-bottom:0px !important;
}
