.navbar {
  background: transparent;
  position: fixed;
  z-index: 999;
  box-shadow: unset;
  padding: 16px 16px;
  height: auto;
  line-height: 1;
  background-color: rgba(0,0,0,.3);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  display: block;
  width: 80px;
}

.navbar-item {
  font-family: var(--font-family-heading);
  text-transform: uppercase;
  line-height: 1;
  margin-top: 4px;
}

.navbar-item a {
  font-size: 10px;
  padding: 0 10px;
}

.navbar-item a:hover {
  color: var(--red-brand);
  transition: color 250ms linear;
}

@media screen and (min-width: 768px) {
    .navbar-item a {
      font-size: 14px;
      padding: 10px 10px;
    }

  .navbar-logo {
    display: block;
    width: 150px;
  }
}
