#builder-gui {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    color: white;
    background-color: rgba(38, 38, 38, 0.85);
    padding: 5px;
    user-select: none;
}

#popups {
    position: absolute;
    top: 32px;
    right: 332px;
}

#builder-gui ul {
    display: flex;
    margin: 0;
}

#builder-gui table * {
    padding: 0;
    margin: 0;
}

#builder-gui table .hidden {
    display: none;
}

#builder-gui button {
    width: 100%;
    height: 18px;
}

#builder-gui select {
    display: block;
    width: 100%;
    font-size: 10pt;
    font-family: sans-serif;
    height: 20px;
}

#builder-gui ul li {
    text-align: center;
    flex-grow: 1;
    background-color: #7e7f80;
    cursor: pointer;
}

#builder-gui ul li:hover {
    background-color: white;
    color: black;
}