@import '../../../styles/base';

.pubRaceLandingPage {
  position: relative;
  width: auto;
  height: auto;
}

.raceLandingShell {
  margin: 0;
  padding: 0;
}

.rightSideContent {
  margin: 0px;
  min-height: 100vh;
  overflow: hidden;
  padding: 0px;
  position: relative;
}

.borderSection {
  color: white;
  margin: 0px;
  min-height: 100vh;
  padding: 5px;
  position: relative;
}

.headerRow {
  border-radius: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px 15px;
}

.headlineText {
  font-size: 20px;
  line-height: 1;
  margin-top: 20px;
  padding: 0 12px;
  font-family: $heading-type;
}

.divider {
  margin-top: 6px;
  height: 1px;
  width: 100%;
  background: white;
}

.infoRow {
  margin-top: 10px;
  font-size: 14px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusText {
  color: $yellow;
}