.react-image-gallery-buttons .image-gallery-svg {
  height: 60px;
}

.react-image-gallery-buttons button:hover {
  color: var(--red-brand) !important;
}

.react-image-gallery-buttons button:focus {
  background-color: unset !important;
}


.gallery-nav nav {
  background-color: unset !important;
}

