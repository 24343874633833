/* h1,h2,h3,h4,h5 {
  font-family: "EurostileExt","Helvetica","Arial";
} */

.hero-container {
  position: relative;
  min-height: 100vh;
}

.hero-image {
  width: 100%;
  height: auto;
  display: block;
  /* Other styles go here */
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hero-title {
  font-family: "EurostileExt","Helvetica","Arial";
  text-transform: uppercase;
  font-size: 42px;
}

.hero-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  padding-top: 128px;
  /* Other styles go here */
}

.section-content-title {
  font-family: "EurostileExt","Helvetica","Arial";
  color: white;
  text-align: center;
}

.section-content-description {
  color: var(--light-gray-text);
  padding: 24px 0;
  max-width: 900px;
  margin: 0 auto;
}

.section-content-steps-group {
  display: flex;
  flex-direction: column;
}
.section-content-step {
  border-color:  var(--red-brand);
  margin: 16px 16px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 10px;
}

.section-content-step-number {
  font-size: 32px;
  font-family: "EurostileExt","Helvetica","Arial";
  color: var(--red-brand);
  margin: 0;
  z-index: 0;
  line-height: 1;
  text-align: left;
  margin-right: 8px;
  margin-top: 8px;
}


@media screen and (min-width: 768px) {
  .hero-image {
    position: relative;
  }
  .hero-container {
    position: relative;
    min-height: unset;
  }
  .section-content-steps-group {
    flex-direction: row;
  }
  .section-content-step-number {
    font-size: 48px;
    margin-right: 16px;
  }
}


