.race-section-large-button {
    width:100%;
    height:60px;
    position:relative;
    font-size:14px;
    margin-bottom:5px;
    margin-top:30px;
    padding:15px;
    background-color:#d0cfce !important;
    color:black !important;
    cursor:pointer;
}

.race-section-large-button:hover {
    background:#FF0000 !important;
    color:white !important;
}

/* RACE DETAILS LAYOUTS */

.race-details-summary-top {
    position:relative;
    z-index:10;
    margin:0px;
    padding:0px;
    margin-bottom:10px !important;
}

.race-details-summary-bar {
    color:black;
    margin:0px !important;
    width:100%;
    padding:15px;
    position:relative;
}

.race-details-summary-bar-item {
    font-size:16px;
    margin-right:20px;
}

.race-details-summary-bar-field {
    margin-right:10px;
}

.race-details-track-img {
    margin-left:-15%;
    margin-top:20px;
}

/* RACE ENTRY LAYOUTS */

.race-entry-spacer {
    position:relative;
    z-index:10;
    margin-top:20px;
    padding-left: 2.5% !important;
}

.race-entry-col {
    width:100%;
    position:relative;
}

.entry-carousel-left-button {
    cursor:pointer;
    position:absolute;
    bottom:12px;
    left:0px;
    font-size:50px;
    z-index:100;
}

.entry-carousel-right-button {
    cursor:pointer;
    position:absolute;
    bottom:12px;
    right:0px;
    font-size:50px;
    z-index:100;
}

.entry-carousel {
    height:450px !important;
}

.entry-carousel-container {
    position:relative;
    height:100%;
    min-height:100% !important;
    font-size:14px;
}

.entry-carousel-main-image {
    height:375px;
    background-image:'';
    background-size:cover !important;
    background-position:center !important;
}

.entry-carousel-text-box {
    font-weight:900;
    padding-top:20px !important;
}

.entry-selection-box {
    border:1px solid white;
    height:650px;
    width:90%;
    margin-left:5%;
    margin-right:5%;
    overflow:hidden;
    background: rgb(0,0,0,.3);
    border-radius:10px;
}

.entry-selection-top {
    height:300px !important;
    width:100%;
}

.entry-selection-bottom {
    border-top:1px solid white;
    height:350px !important;
    width:100%;
}

.entry-selection-item-title {
    padding:15px 15px 15px 15px;
    font-weight:500;
    margin-bottom:0px;
    border-bottom:1px solid white;
}

.entry-selection-item-specs {
    padding:15px 15px 0px 15px;
    font-weight:500;
    margin-bottom:0px;
}


/* LINEUP LAYOUTS */

.lineup-racer-main-image {
    color:white;
    width:100%;
    height:250px;
    position:relative;
    overflow:hidden;
}

.lineup-racer-stats-box {
    width:100%;
    padding-bottom:20px;
    position:relative;
}

/* RESULTS LAYOUTS */
.results-podium-stats {
    color:white;
    width:100%;
    position:relative;
    border-bottom:1px solid white;
}
.results-podium-stats-top {
    text-align:right;
    font-size:18px;
}

.results-podium-icons-coin {
    width:35px;
    margin-top:-5px;
    margin-right:10px;
}

.results-podium-icons-xp {
    width:30px;
    margin-top:-3px;
    margin-right:5px;
}


.item-spec-row {
    font-size:12px;
    margin-bottom:5px;
}

.item-spec-bar {
    height: 15px;
    margin-top:5px;
    background-color: #EBEBEB !important;
}

.item-spec-bar-fill {
    background-color: #EE1E26 !important;
}

.race-entry-top-banner {
    position:relative;
    margin-top:-10px;
    margin-bottom:20px;
    padding:10px;
    border-radius:5px;
}

.race-entry-banner-title {
    margin-left:2%;width:96%;margin-top:10px;line-height:125%;
}

.race-entry-banner-title-mobile {
    margin-left:1%;width:98%;margin-top:10px;
}

.race-entry-banner-tiny-text {
    margin-left:2%;width:96%;margin-top:-10px;
}

.race-entry-banner-chevron-mobile {
    float:right;font-size:80px;margin-top:5px;
}

.race-entry-banner-chevron {
    position:absolute;right:0;top:20;font-size:80px;
}

.race-entry-button-shell {
    text-align:center;
    border-radius:5px;
    margin-left:30% !important;
    margin-right:30% !important;
    width:40% !important;
}
.race-entry-button-shell-mobile {
    text-align:center;border-radius:5px;
}

.race-entry-button {
    font-size:24px;padding:0px;margin-top:15px;margin-bottom:15px;text-align:left;
}
.race-entry-button-text {
    float:left;
    margin-top:0px;
}
.race-entry-button-chevron {
    float:left;font-size:24px;
}