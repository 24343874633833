.page-main-section {
    position:relative;
    font-size:18px;
    margin-left:10%;
    margin-right:10%;
    width:80%;
    margin-top:0px;
    border-radius:10px;
    margin-bottom:0px;
    pointer-events: none;
}

/* body {
    overflow: hidden !important;
} */

#gui {
    user-select: none;
    -webkit-user-select: none;
}

#game-container {
    position:relative;
    float:left;
    width:100vw;
    height:100vh;
}

#mobile-container {
    float:left;
    position:absolute;
    width:25vw;
    height:100vh;
    border-radius:10px;
    top:0px;
    right:0px;
    background-color:transparent;
    min-width:315px;
}

#exrprimarylogo {
    position:absolute;
    top:20px;
    left:20px;
    z-index:1000;
    cursor:pointer;
    width:19%;
    max-width:130px;
}

#raceprogress {
    display:none;
    padding:10px 20px;
    font-size: calc(14px + 0.5vw);
    position: absolute;
    top: 20px;
    left: 175px;
    background: rgb(0,0,0,.3);
    color: white;
    font-family:helvetica;
    border-radius: 10px;
    border:1px solid white;
}

#racecountdown {
    display:none;
    padding:10px 20px;
    font-size: calc(14px + 0.5vw);
    position: absolute;
    top: 20px;
    left: 175px;
    background: rgb(0,0,0,.3);
    color: white;
    font-family:helvetica;
    border-radius: 10px;
    border:1px solid white;
}

#racerbanner {
    display:none;
    padding: 0px;
    font-size: 60px;
    position: absolute;
    top: 20px;
    left: 0px;
    width:100%;
    text-align:center;
    right:auto;
    color: white;
    font-family:helvetica;
    z-index:10000;
}

#racerbannertxt {
    font-size: calc(24px + 3vw);
    background: rgb(0,0,0,.9);
    color: white;
    border-radius:10px;
    padding:10px 20px;
    border:1px solid white;
}

#players {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    overflow: hidden;
}

#players.visible {
    display: block;
}

#players .pointer {
    position: absolute;
    background-color: transparent;
}

#players .pointer.hidden {
    display: none;
}

#players .pointer .name-tag {
    color: white;
    font-size: 12pt;
    white-space: nowrap;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid;
}

#players .pointer .lead {
    position: absolute;
    bottom: -1px;
    right: 100%;
    height: 1px;
    transform-origin: bottom right;
}

#minimap {
    position: absolute;
    left: 20px;
    bottom: 20px;
    max-width: 300px;
    max-height: 300px;
    width: 20vw;
    height: 20vw;
}

#minimap svg {
    width: 100%;
    height: 100%;
}

#minimap .track {
    fill: var(--color-minimap-track);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));
}

#minimap .finish {
    stroke: var(--color-minimap-finish);
    stroke-width: 2;
    stroke-dasharray: 2;
}

#minimap .special {
    stroke: var(--color-minimap-highlight);
    stroke-width: 1;
}

#leaderboardbox {
    position:relative;
    padding: 14px;
    font-size: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    overflow:hidden;
    width: 90%;
    left:4%;
    top:20px;
}

.leaderboardtop {
    border-bottom:1px solid white;
    width:100%;
}

.leaderboardbottom {
    border-top:1px solid white;
    width:100%;
    position:relative;
    margin-bottom:0px;
    text-align:center;
    font-size:16px;
    padding-top:10px;
    margin-top:10px;
    line-height:1;
    padding-bottom:0px;
}

#leaderboardtitle {
    font-size:20px;
}

.leaderboardrow {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid white;
    position: relative;
    transition: 0.2s;
    transition-timing-function: ease-out;
    background: linear-gradient(to right, transparent 0%, transparent 45%, white 55%, white 100%);
    background-size: 400% 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    font-family:helvetica;
}

.leaderboardrow:hover,
.leaderboardrow.tracked {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.58), transparent);
    cursor: pointer;
}

.leaderboardrowlegendary {
    border: 1px solid gold;
}

.leaderboardrowexiled {
    border: 1px solid #59EA79;
}

.leaderboardrow .avatar {
    height: 2.5vw;
    max-height:32px;
    margin: -5px -10px;
    background-color: transparent;
}

.leaderboardrow .avatar img {
    height: 100%;
}

.leaderboardrow .number {
    position: absolute;
    right: 5px;
    top:5px;
    display: flex;
    flex-direction: row-reverse;
    z-index: 10;
    color:white;
    font-size:18px;
    font-weight:bold;
    text-shadow: 1px 1px black;
}

.leaderboardrow .background {
    position: absolute;
    margin: -5px -10px;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    z-index: 1;
}

.leaderboardrow .background .stripe {
    height: 100%;
    transform: skewX(-30deg);
    margin-left: 5px;
}

.leaderboardrow h2 {
    font-size:18px;
    margin: 0;
    padding:0px;
    padding-left: 14px;
    padding-right:5px;
    float:left;
    font-family:helvetica;
    line-height: 1.4 !important;
}

.leaderboardrow .predict {
    float:left;
    margin: 0;
    padding:0px;
    line-height: 1.1;
    margin-right:2px;
}

.leaderboardrow .predict i {
    font-size:16px;
    padding:0px;
    margin-top:4px;
}

.leaderboardrow .star {
    float:left;
    margin: 0;
    padding:0px;
    line-height: 1.1;
    margin-right:2px;
}

.leaderboardrow .star i {
    font-size:16px;
    padding:0px;
    margin-top:2px;
}

.leaderboardrow .anger {
    float:left;
    margin: 0;
    padding:0px;
    line-height: 1.4;
    margin-right:2px;
    font-size:14px;
}

.leaderboardrow .anger i {
    font-size:16px;
    padding:0px;
    margin-top:2px;
}

.leaderboardrow.boosting {
    /* background: #FF0000 !important;
    color:white !important; */
    color:white; 
}

.leaderboardrow.finished {
    transition: 0.3s;
    background-position: 100%;
    color: black;
}

.leaderboardrow .result {
    color: black;
    transition: 1s;
    position: absolute;
    height: 100%;
    right: 0;
    padding-right: 16px;
    display: flex;
    align-items: center;
    margin: -5px -10px;
}

.leaderboardrow .result.active {
    width: 32px;
}

.leaderboardimg {
    position:absolute;
    right:0;
    top:0;
}

#leaderboardracers {
    width:100%;
    margin:0;
    padding:0;
    margin-top:20px;
}

#finalboardholder {
    padding: 30px;
    font-size: 60px;
    position: fixed;
    top: 30px;
    left: 0;
    width:100%;
    text-align:center;
    color: white;
    font-family:helvetica;
}

#finalboardbox {
    padding: 20px;
    padding-left:30px;
    font-size: 20px;
    position: absolute;
    top: 5px;
    width:90%;
    left:5%;
    background: rgb(0,0,0,.6);
    background-color:#272727;
    background: rgb(0,0,0,.6);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    height:auto;
    display:block;
    margin-bottom:50px;
}

#finalboardboxmobile {
    position:relative;
    padding: 10px;
    font-size: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    overflow:hidden;
    width: 90%;
    left:4%;
    top:20px;
}

#finalboard-next-button {
    position:relative;
    width:90%;
    margin-left:5%;
    margin-left:5%;
    text-align:center;
    border-radius:5px;
    margin-top:40px;
}

.finalboard-next-inner-button {
    font-size:18px;padding:0px;margin-top:15px;margin-bottom:15px;text-align:left;
}
.finalboard-next-button-text {
    float:left;
    margin-top:0px;
}
.finalboard-next-button-chevron {
    float:left;font-size:24px;
}


#finalboard-next-button-small {
    position:relative;
    border-radius:5px;
    margin-top:0px;
}

.finalboard-next-inner-button-small {
    font-size:14px;padding:0px;margin-top:10px;margin-bottom:10px;text-align:left;
}
.finalboard-next-button-text-small {
    float:left;
    margin-top:0px;
}
.finalboard-next-button-chevron-small {
    float:left;font-size:20px;
}


.finalboardsection {
    min-width:300px;
    width:80%;
}

.finalboardtop {
    border-bottom:1px solid white;
    width:100%;
    margin:0px;
    padding:0px;
}

#finalboardtitle {
    font-size:24px;
    margin-bottom:5px;
}

.finalboardrow {
    font-size:18px;
    width:100%;
    margin-bottom:10px;
    padding:5px 10px;
    border:1px solid white;
    position:relative;
}

.finalboardbox {
    font-size:18px;
    width:100%;
    margin-bottom:10px;
    padding:5px 10px;
    border:1px solid white;
    position:relative;
}

.finalboardimg {
    position:absolute;
    right:0px;
    top:0px;
}

#finalraceresults {
    width:100%;
    margin:0px;
    padding:0px;
    margin-top:0px;
    margin-bottom:0px;
}

.finish-screen-col {
    position:relative;
    z-index:10;
    margin-top:10px;
    padding:10px !important;
    font-size: 14px;
    background: rgb(0,0,0,.3);
    background-color:#3A3A3A;
    background: rgb(0,0,0,.5);
    color: white;
    border-radius: 5px;
    border:0px solid white;
    font-family:helvetica;
    overflow:hidden;
}

.finish-screen-racer-name {
    margin:0px 10px !important;
}

.finish-screen-racer-divider {
    margin:5px !important;
    height:1px;
    background:white;
    margin-bottom:10px !important;
}

.finish-screen-racer-image {
    border-radius: 5px;
    margin:5px !important;
    margin-top:10px;
}

.finish-screen-racer-sub {
    border-radius: 5px;
    margin:5px !important;
    margin-top:10px;
}

.finish-screen-racer-ship {
    width:49.25%;
    border-radius: 5px;
}

.finish-screen-racer-stats {
    width:49.25%;
    border-radius: 5px;
    margin-left:1.5%;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size:12px;
    padding:10px;
    padding-top:5px;
}

.finish-screen-stats-row {
    margin:10px 0px 0px 0px !important;
}
.finish-screen-stats-subrow {
    margin:0px !important;
}

.gamemodule {
    padding:10px 20px;
    font-size: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
}

.gamemodulehighlight {
    color: #FFF367 !important;
    border:1px solid #FFF367 !important;
}

#gamesettingsmenu {
    position: absolute;
    bottom:20px;
    right:20px;
    max-width:90%;
    z-index: 10000;
}

#startbuttons {
    top:20px;
    left:175px;
    position: absolute;
    display: flex;
    flex-direction: row;
}

#startbuttons div {
    position: relative;
    cursor: pointer;
    margin-right: 16px;
}

#startbuttons div:hover {
    background: #FF0000;
}

#startgamebutton {
    cursor: pointer;
    float:left;
    position: relative;
    font-size:calc(10px + 0.5vw);
    z-index:1;
}

#startlineupbutton {
    display:block;
    float:left;
    cursor: pointer;
    position: relative;
    font-size:calc(10px + 0.5vw);
    z-index:1;
}

#streamingmenu {
    position:absolute;
    width:15vw;
    height:100%;
    background-color: #141414;
    border-radius:0px;
    min-width:auto; 
    right:0px;
    top:0px;
}

.streaminggamesize {
    width:85vw !important;
}

.streamingmenuposition {
    right: 15.5vw !important;
}

.streamcamerabutton{
    cursor:pointer;
    font-size:16px;
    text-align:center;
}

.streamcamerabutton :hover{
    color:#FFF367;
    border:1px solid #FFF367;
}

.podiumbuttons {
    display:none;
    cursor: pointer;
    position: absolute;
    font-size:calc(10px + 0.5vw);
    z-index:1;
}

.replaybutton {
    cursor: pointer;
    position: absolute;
    font-size:calc(10px + 0.5vw);
    z-index:1;
}

.gsettingsbutton {
    float:left;
    font-size:14px;
    line-height:1;
}

.gsettingsbuttonname {
    float:left;
    margin-right:5px;
    font-size:14px;
}

.gsettingsbuttonvalue {
    float:left;
    height: 16px;
    font-size:14px;
    cursor:pointer;
}
.gsettingsdivider {
    float:left;
    height:16px;
    width:1px;
    background:white;
    margin-left:10px;
    margin-right:10px;
}

.gameddmenu {
    background: rgb(0,0,0,.8);
    border-radius: 10px;
    border:1px solid white;
    font-size:14px;
    color:white !important;
    margin-bottom:50px;
    top:-150px !important;
    left:30px !important;
}

.gameddmenuoption {
    font-size:14px;
    color:white !important;
    text-align:center !important;
    min-height:auto !important;
    padding-top:10px !important;
    padding-bottom:10px !important;
    line-height:1 !important;
}

.gameddmenuoption:hover{
    color:black !important;
}

#pausegamebutton {
    padding:10px 20px;
    font-size: 20px;
    position: fixed;
    bottom: 90px;
    right: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    display:none;
}

#finishgamebutton{
    padding:10px 20px;
    font-size: 20px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    display:none;
}

#planetfactionnametest {
    position: fixed;
    bottom:20px;
    left:20px;
    width:auto;
    font-size:14px;
    max-width: 300px;
    max-height: 300px;
    width: 40vh;
} 

.trackfactionicon {
    position:absolute;
    top:2px;
}

#trackfactionname {
    position:relative;
    width:100%;
    font-size:16px;
    text-align:center;
}


@media screen and (max-width: 950px) and (max-height: 525px) {
    #game-container {
        width:68vw;
        height:100vh;
    }

    #mobile-container {
        position:absolute;
        width:32vw;
        height:100vh;
        background-color: #141414;
        border-radius:0px;
        min-width:auto; 
    }

    

    #minimap {
        width:12vw;
        height:12vw;
    }

    #raceprogress {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
        font-size:calc(10px + 0.5vw);
    }

    #startbuttons {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
    }

    /* #startgamebutton {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
    }

    #startlineupbutton {
        left:auto;
        top:auto;
        bottom:20px;
        right:220px;
    } */

    #racerbanner {
        top: -10px;
        text-align:right;
        
    }

    .podiumbuttons {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
        position:static !important;
    }

    .replaybutton {
        left:auto;
        top:auto;
        bottom:20px;
        right:250px;
    }

    #racerbannertxt {
        font-size: calc(14px + 1.25vw);
        margin-right:20px;
    }


    #gamesettingsmenu {
        margin-bottom:-10px;
        border:0px;
        background-color:transparent;
    }

    .gsettingsbuttonname {
        height: 14px;
        font-size:14px;
    }
    
    .gsettingsbuttonvalue {
        height: 14px;
        font-size:14px;
        vertical-align:sub;
    }

    #leaderboardbox {
        background-color:#272727;
        border:0px;
    }

    #exrprimarylogo {
        top:10px;
        left:10px;
    }
}

@media screen and (max-width: 750px) {
    #game-container {
        width:100vw;
        height:60vh;
    }

    #mobile-container {
        position:relative; 
        width:100vw;
        height:auto;
        background-color: #141414;
        border-radius:0px;
        min-width:auto;
    }

    

    #raceprogress {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
        font-size:calc(10px + 0.5vw);
    }

    #minimap {
        width:20vw;
        height:20vw;
    }

    #gamesettingsmenu {
        position:relative;
        float:left;
        margin:0 auto;
        width:91%;
        left:4%;
        bottom:0px;
        margin-top:30px;
        margin-bottom:20px;
    }

    #startbuttons {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
    }

    /* #startgamebutton {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
    }

    #startlineupbutton {
        left:auto;
        top:auto;
        bottom:20px;
        right:150px;
    } */

    .podiumbuttons {
        left:auto;
        top:auto;
        bottom:20px;
        right:20px;
        position:static !important;
    }

    .replaybutton {
        left:auto;
        top:auto;
        bottom:20px;
        right:250px;
    }

    #racerbanner {
        top: -20px;
        text-align:right;
        
    }

    #racerbannertxt {
        font-size: calc(14px + 1.25vw);
        margin-right:20px;
    }

    #exrprimarylogo {
        top:10px;
        left:10px;
    }
}