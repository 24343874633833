:root {
    --color-minimap-track: rgba(255, 255, 255, 0.79);
    --color-minimap-finish: #ce0909;
    --color-minimap-highlight: var(--color-minimap-finish);

    --minimap-blip-radius: 5;
    --minimap-blip-left: -4;
    --minimap-blip-right: 11;

    --racer-spacing-min: 3.5;
    --racer-spacing-max: 2.20;
    --racer-min-lanes: 6;
    --racer-max-lanes: 11;
    --racer-model-scale-biggest: .003;
    --racer-model-scale-big: .002;
    --racer-model-scale-small: .00120;
    --racer-bank-magnitude: .8;
    --racer-look-ahead-turn: 10;
    --racer-look-ahead-bank: 40;

    --racer-trail-x: 2.6;
    --racer-trail-y: 1.3;
}