#track-viewer-league {
    overflow:hidden;
}

#track-viewer-league svg {
    width: 100%;
    height: 100%;
    --color-minimap-track: white;

}

#track-viewer-league .track {
    fill: var(--color-minimap-track);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));
}

#track-viewer-league .finish {
    stroke: var(--color-minimap-finish);
    stroke-width: 2;
    stroke-dasharray: 2;
}

#track-viewer-league .special {
    stroke: var(--color-minimap-highlight);
    stroke-width: 1;
}