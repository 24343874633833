.raceday-track-viewer {
    overflow:hidden;
}

.raceday-track-viewer svg {
    width: 100%;
    height: 100%;
    --color-minimap-track: white;

}

.raceday-track-viewer .track {
    fill: var(--color-minimap-track);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));
}

.raceday-track-viewer .finish {
    stroke: var(--color-minimap-finish);
    stroke-width: 2;
    stroke-dasharray: 2;
}

.raceday-track-viewer .special {
    stroke: var(--color-minimap-highlight);
    stroke-width: 1;
}

.itemtriangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent white transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
}

.itemstar {
    right: 1;
    top: 1;
    position: absolute;
}