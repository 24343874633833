#main-landing-page {
    margin-bottom:0px;
    margin-top:0px;
    border-top:0px;
}

.cursor-pointer {
    cursor:pointer;
}

#splash-main {
    width:100%;
    position:absolute;
    left:0%;
    top:9%;
    color:white;
    margin-top:25px;
    text-align:center;
}

.splash-logo {
    float:left;
    width:100%;
    text-align:center;
}

.splash-headline {
    float:left;
    width:100%;
    margin-top:50px;
    font-size:18px;
}

.splash-headline {
    float:left;
    width:100%;
    margin-top:50px;
    font-size:18px;
}

.splash-button-bullet-top {
    float:left;
    width:100%;
    margin-top:50px;
    font-size:12px;
}

.splash-button-bullet-bottom {
    float:left;
    width:100%;
    margin-top:-2px;
    font-size:12px;
}

.splash-main-button-wrapper {
    float:left;
    width:100%;
    margin-top:-9px;
    font-size:24px;
}

.splash-main-button {
    font-size:24px;
    padding-right:0px;
    padding-left:0px;
}

.splash-main-button-bracket {
    font-family:'Roboto';
    font-weight:100;
}

.splash-discord-top {
    position:fixed;
    right:30px;
    top:0px;
}

.splash-discord-button-inline {
    float:left;
    width:100%;
    font-size:14px;
    margin-top:30px;
    margin-bottom:30px;
}

.splash-mint-status-text {
    float:left;
    margin-left:10%;
    width:80%;
    font-size:14px;
    margin-top:60px;
}

.splash-modal-title-alt {
    margin-top:5px;
    display:none;
}

.splash-modal-confirmtxt {
    font-size:18px;
    margin-top:25px;
    display:none;
}

.splash-submodal-button {
    margin-top:-15px;
    font-size:12px;
    padding-right:0px;
    padding-left:0px;
}

.splash-modal-button {
    margin-top:-5px;
    font-size:24px;
    padding-right:0px;
    padding-left:0px;
}

.splash-modal-loader {
    margin-top:10px;
    display:none;
}

.firstsection {
    margin-top:80px;
}

.event-register-large-headline {
    font-size:40px;
    line-height:1;
}

.event-register-large-headline-mid {
    font-size:40px;
    line-height:1;
}

.headlinebrow {
    font-size:24px;
}

.event-register-subheadline {
    font-size:24px;
}

.event-register-subheadline-mid {
    font-size:24px;
}

.event-register-subheadline-small {
    float:left;
    font-size:24px;
}

.event-register-formheader {
    font-size:18px;
}

.event-register-formbutton {
    float:left;
    font-size:20px;
    height:42px;
}

.event-register-formbutton2 {
    float:left;
    font-size:20px;
    height:42px;
}

.section-triple-subtext {
    width:90%;
    font-size:18px;
}

.section-line-seperator {
    text-align:left;
    float:left;
    border-width:2px;
}

.overlaymainphoto {
    position:relative;
    padding:0px;
}
.overlaysubinfo {
    height:100px;
    padding:0px;
}

.overlaysubinfolight {
    height:100px;
    padding:0px;
}

.overlaysubinfophoto {
    display:none;
}

.overlaysubinfotext {
    padding:0px;
    margin:0px;
}

.overlaybutton {
    border-radius:5px;
}

.user-current-position {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom:20px !important;
    padding-top:0px !important;
    padding-left:0px;
    padding-right:0px;
    margin-top:-10px;
}

.bifrost-gradient {
    background: linear-gradient(90deg, #004aad, #cb6ce6) !important;
}

.bifrost-light-purple-text {
    color: #a377fa !important;
}

.bifrost-mid-purple-text {
    color: #8c52ff !important;
}

.bifrost-dark-purple-text {
    color: #5e17eb !important;
}

.nviewer-info-carousel-button-pink {
    float:right;
    background-color:white;
    height:10px;
    border-radius:10px;
    width:10px;
    cursor:pointer;
    margin-right:10px;
  }
  
  .nviewer-info-carousel-button-pink:hover{
    background-color:#e61f93;
  }
  
  .nviewer-info-carousel-button-pink:hover{
    background-color:#e61f93;
  }
  .nviewer-info-carousel-button-pink-active{
    background-color:#e61f93;
  }



  .nviewer-info-carousel-button-base-blue {
    float:right;
    background-color:white;
    height:10px;
    border-radius:10px;
    width:10px;
    cursor:pointer;
    margin-right:10px;
  }
  
  .nviewer-info-carousel-button-base-blue:hover{
    background-color:#3773f5;
  }
  
  .nviewer-info-carousel-button-base-blue:hover{
    background-color:#3773f5;
  }
  .nviewer-info-carousel-button-base-blue-active{
    background-color:#3773f5;
  }


@media screen and (max-width: 450px) {

    .event-register-subheadline-small {
        font-size:18px !important;
        text-align:center !important;
        margin-top:10px;
    }
}

@media screen and (max-width: 750px) {
    .firstsection {
        margin-top:30px;
    }

    .event-register-large-headline {
        font-size:24px !important;
        text-align:center;
    }

    .event-register-large-headline-mid {
        font-size:30px !important;
        text-align:center !important;
        line-height:1.1;
    }

    .headlinebrow {
        font-size:18px !important;
    }

    .event-register-subheadline {
        font-size:18px !important;
        text-align:center;
        margin-top:10px;
    }

    .event-register-subheadline-mid {
        font-size:18px !important;
        text-align:center !important;
        margin-top:10px;
    }
    

    .event-register-formheader {
        font-size:14px;
    }
    
    .event-register-formbutton {
        float:right;
        margin-right:-10px;
        font-size:14px;
        height:34px;
    }

    .event-register-round-button {
        padding:20px;
    }

    .main-sponsor-icons {
        text-align:center;
        margin-bottom:10px;
    }

    .section-triple-subtext {
        width:100%;
        font-size:14px;
        margin-bottom:30px;
    }

    
}
  
@media screen and (max-width: 950px) {

    .firstsection {
        margin-top:50px;
    }
    .event-register-large-headline {
        font-size:30px;
        text-align:center;
        line-height:1.1;
    }

    .event-register-large-headline-mid {
        font-size:30px;
        text-align:left;
        line-height:1.1;
    }

    .headlinebrow {
        font-size:20px;
    }

    .event-register-subheadline {
        font-size:20px;
        text-align:center;
        margin-top:10px;
    }

    .event-register-subheadline-mid {
        font-size:20px;
        margin-top:10px;
        text-align:left;
    }

    .event-register-formheader {
        font-size:16px;
    }

    .section-triple-subtext {
        width:100%;
        font-size:16px;
        margin-bottom:30px;
    }

    .section-line-seperator {
        width:80% !important;
        margin-left:10%;
        border-width:1px;
    }

    .overlaysubinfo {
        background:#272727;
        border-radius:5px;
        margin-top:-15px;
        margin-bottom:0px;
    }

    .overlaysubinfolight {
        background:#EBEBEB;
        border-radius:5px;
        margin-top:-15px;
        margin-bottom:0px;
    }

    .overlaysubinfophoto {
        display:block;
    }

    .overlaysubinfotext {
        padding:10px;
    }

    .overlaymainphoto {
        display:none;
    }

    
}

