#globalmusicplayer {
    margin-bottom: 0px;
    position: fixed;
    bottom: 10px;
    left: 15px;
    font-size: 12px;
    width:20%;
    display:none;
}

#music-player-container {
    background: #3A3A3A !important;
    border-radius: 5px;
    height: 85px;
}

#music-player-window {
    border-radius: 5px;
    height: 30px;
    background-color:#606060;
    margin:8px 0px;
    padding:5px 7px;
    overflow:hidden;
    line-height: 2;
    position:relative;
    margin-top:12px;
}

#music-player-marquee {
    color: white;
    width:200%;
    font-size:10px;
}

#music-player-gif {
    position:absolute;
    height: 30px;
    right:10px;
    background-color:#606060;
    top:2px;
    display:none;
}