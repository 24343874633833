/** Section **/

.section-container {
  display: flex;
  flex-direction: column;
  padding: 64px 0;
}

.section-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-image {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  /* Other styles go here */
}

.section-text {
    color: var(--light-gray-text);
  padding: 32px 0;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

}

.section-subtitle {
  color: #FF1616;
  letter-spacing: 1.4px;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 4px 0;
}

.section-description {
  font-size: 1rem;
}

.section-title {
  font-size: 32px;
  line-height: 1;
  margin: 0;
  color: white;
  text-transform: uppercase;
}

.section-link-group {
  display:  grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: center;
}

.section-link {
  border-radius: 40px;
  height: 48px;
  padding: 0 16px;
  background-color: var(--red-brand);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  padding-top: 4px;
  text-transform: uppercase;
  font-family: "EurostileExt","Helvetica","Arial";
}

.section-additional-note {
    color: var(--light-gray-text);
  font-size: 16px;
  padding-top: 24px;
}

.section-additional-note-label {
  color: var(--red-brand);
  margin-right: 2px;
  text-transform: uppercase;
}

.section-text-with-block-text-image {
  height: auto;
  width: 500px;
  opacity: 0.2;
}
@media screen and (min-width: 768px) {
  .section-container {
    flex-direction: row;
    /* Desktop styles go here */
  }
  
  .section-reverse {
    flex-direction: row-reverse;
  }

  .section-block {
    width: 50%;
  }

  .section-image {
    width: 100%;
    /* Desktop styles go here */
  }

  .section-text {
    width: 50%;
    font-size: 32px;
    padding: 32px 32px;
    /* Desktop styles go here */
  }
}

