#renderer {
    background: radial-gradient(circle, #9e9e9e, #0a0a0a);
}

.page-main-ship {
    position:relative;
    font-size:18px;
    margin-left:10%;
    margin-right:10%;
    width:80%;
    margin-top:0px;
    border-radius:10px;
    margin-bottom:0px;
}

.select-wrapper .caret {
    fill:#606060;
}

#gui {
    user-select: none;
    -webkit-user-select: none;
}

.menumodule {
    padding:10px 20px;
    font-size: 20px;
    background: rgb(0,0,0,.5);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
}

#shipviewermenu {
    position: fixed;
    bottom:140px;
    right:600px;
}

.shipsettingsbutton {
    float:left;
    font-size:14px;
}

.shipsettingsbuttonname {
    float:left;
    margin-right:5px;
}

.shipsettingsbuttonvalue {
    float:left;
    height: 20px;
    cursor:pointer;
}
.shipsettingsdivider {
    float:left;
    height:20px;
    width:1px;
    background:white;
    margin-left:10px;
    margin-right:10px;
}

.shipddmenu {
    background: rgb(0,0,0,.8);
    border-radius: 10px;
    border:1px solid white;
    font-size:14px;
    color:white !important;
    margin-bottom:50px;
    top:-100px !important;
    left:10px !important;
}

.shipddmenuoption {
    font-size:14px;
    color:white !important;
    text-align:center !important;
    min-height:auto !important;
    padding-top:10px !important;
    padding-bottom:10px !important;
    line-height:1 !important;
}

.shipddmenuoption:hover{
    color:black !important;
}

.exr-filter-bar {
    background-color:#272727;
    border-radius:10px;
    min-height:50px;
    margin-top:2.5px;
    margin-bottom:2px;
    color:white;
}

.exr-more-bar {
    background-color:#272727;
    border-radius:10px;
    margin-top:2.5px;
    color:white;
    padding-top:10px !important;
    padding-bottom:10px !important;
    margin-bottom:5px;
    cursor:pointer;
}
.exr-more-bar:hover {
    background:white;
}


.exr-modal-segment-col {
    padding:2.5px !important;
}

.exr-modal-segment {
    background-color:#272727;
    border-radius:10px;
    cursor:pointer;
    position:relative;
}

.exr-modal-segment-image {
    width:100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow:hidden;
}

.exr-modal-segment-description {
    width:100%;
    padding:5px;
    font-size:16px;
    position:relative;
}

.exr-modal-segment-title {
    width:100%;
    padding:5px;
    padding-left:20px;
    font-size:18px;
}

.exr-modal-segment-subtitle {
    width:100%;
    padding-left:20px;
    padding-bottom:5px;
    font-size:14px;
}

.exr-modal-segment-rank {
    position:absolute;
    right:10px;
    bottom:10px;
    border-radius:5px;
    min-width:25px;
    font-size:14px;
    text-align:center;
    padding:2px;
    padding-top:3px;
    border:1px solid #3A3A3A;
    color:white;
}


.filter-row-title {
    font-size:20px;
    margin:10px;
    padding-left:10px;
    padding-right:20px;
    border-right:1px solid #606060;
    float:left;
}

.filter-row-title-mobile {
    font-size:20px;
    margin-top:10px;
    padding-right:20px !important;
    border-right:1px solid #606060;
}

.filter-row-filter-shell {
    font-size:14px;
    padding:5px 9px;
    border-radius:10px;
    margin-top:8px;
    position:relative;
}

.filter-submit-button {
    position:absolute;
    right:8px;
    top:6px;
    cursor:pointer;
}

.filter-submit-button-icon {
    color:#606060;
    border-left:1px solid #3A3A3A;
}

.filter-submit-button-icon:hover {
    color:white;
    border-left:1px solid #3A3A3A;
}

.stats-viewer-primary-icon {
    line-height:1;
}

.stats-viewer-headline {
    font-size:32px;
}

.stats-viewer-subheadline {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size:12px;
    margin-top:5px;
}

.stats-viewer-stats-headline {
    margin-top:30px;
    font-size:18px;
    margin-bottom:5px;
}




.viewer-search {
    border:1px solid #3A3A3A !important;
    color:white;
    width:auto;
    font-size:12px !important;
    font-family:'NBInternationalPro-Light','Roboto', Helvetica;
    padding-left:10px !important;
    height: auto;
    line-height: 1;
    border-radius:5px  !important;
    width:60px !important;
    background:transparent;
    height:28px !important;
    margin-top:-2px !important;
    margin-left:7px !important;
}

.viewer-search:focus {
    border:1px solid #3A3A3A !important;
    color:white;
    width:auto;
    font-size:12px !important;
    font-family:'NBInternationalPro-Light','Roboto', Helvetica;
    padding-left:10px !important;
    height: auto;
    line-height: 1;
    border-radius:5px  !important;
    width:60px !important;
    background:transparent;
    height:28px !important;
    margin-top:-2px !important;
    -webkit-box-shadow: none !important;
    margin-left:7px !important;
}