.page-main-section {
    position:relative;
    font-size:18px;
    margin-left:10%;
    margin-right:10%;
    width:80%;
    margin-top:0px;
    border-radius:10px;
    margin-bottom:0px;
}

#raceprogress {
    padding:10px 20px;
    font-size: 20px;
    position: fixed;
    top: 20px;
    left: 175px;
    background: rgb(0,0,0,.3);
    color: white;
    font-family:helvetica;
    border-radius: 10px;
    border:1px solid white;
}

#racerbannerretro {
    padding: 30px;
    font-size: 60px;
    position: fixed;
    top: 30px;
    left: 0px;
    width:100%;
    text-align:center;
    color: white;
    font-family:helvetica;
    display:none;
}

#leaderboardbox {
    padding: 20px;
    font-size: 20px;
    position: fixed;
    top: 20px;
    right: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    overflow:hidden;
}

#leaderboardsection {
    min-width:250px;
}

.leaderboardtop {
    border-bottom:1px solid white;
    width:100%;
    margin:0px;
    padding:0px;
}

#leaderboardtitle {
    font-size:20px;
}

.leaderboardrow {
    font-size:18px;
    width:100%;
    margin-bottom:10px;
    padding:5px 10px;
    border:1px solid white;
    position:relative;
}

.leaderboardimg {
    position:absolute;
    right:0px;
    top:0px;
}

#raceresults {
    width:100%;
    margin:0px;
    padding:0px;
    margin-top:20px;
    margin-bottom:0px;
}

#finalboardholder {
    padding: 30px;
    font-size: 60px;
    position: fixed;
    top: 30px;
    left: 0px;
    width:100%;
    text-align:center;
    color: white;
    font-family:helvetica;
}

#finalboardboxretro {
    padding: 20px;
    font-size: 20px;
    position: fixed;
    top: 30px;
    left:35%;
    background: rgb(0,0,0,.95);
    color: white;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    overflow:hidden;
    height:90vh;
    display:none;
}

#finalboard-next-button {
    position:absolute;
    bottom:20px;
    left:0px;
    width:90%;
    margin-left:5%;
    margin-left:5%;
    text-align:center;
    border-radius:5px;
}

.finalboard-next-inner-button {
    font-size:18px;padding:0px;margin-top:15px;margin-bottom:15px;text-align:left;
}
.finalboard-next-button-text {
    float:left;
    margin-top:0px;
}
.finalboard-next-button-chevron {
    float:left;font-size:24px;
}


#finalboardsection {
    min-width:400px;
}

.finalboardtop {
    border-bottom:1px solid white;
    width:100%;
    margin:0px;
    padding:0px;
}

#finalboardtitle {
    font-size:20px;
}

.finalboardrow {
    font-size:18px;
    width:100%;
    margin-bottom:10px;
    padding:5px 10px;
    border:1px solid white;
    position:relative;
}

.finalboardimg {
    position:absolute;
    right:0px;
    top:0px;
}

#finalraceresults {
    width:100%;
    margin:0px;
    padding:0px;
    margin-top:20px;
    margin-bottom:0px;
}

#startgamebuttonold {
    padding:10px 20px;
    font-size: 28px;
    position: fixed;
    bottom:20px;
    right:20px;
    background: rgb(0,0,0,.3);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica
}

#startgamebuttonold:hover {
    background: #FF0000;
}

#pausegamebutton {
    padding:10px 20px;
    font-size: 20px;
    position: fixed;
    bottom: 90px;
    right: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    display:none;
}

#finishgamebutton{
    padding:10px 20px;
    font-size: 20px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0,0,0,.3);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border:1px solid white;
    font-family:helvetica;
    display:none;
}