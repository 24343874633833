.section-hero-with-image {
  color: var(--light-gray-text);
  padding: 64px 16px;
}

.section-hero-with-image-title {
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.section-hero-with-image-subtitle {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--red-brand);
  padding: 8px;
  color: white;
}

.section-hero-with-image-description {
  padding: 24px 0;
}

.section-hero-with-image-img {
  display: block;
  width: 100%;
  height: auto;
}



@media screen and (min-width: 768px) {
  .section-hero-with-image-title {
    font-size: 64px;
  }

  .section-hero-with-image-subtitle {
    font-size: 32px;
    margin: 0 auto;
  }
}