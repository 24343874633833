.section-image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 4px;
}

.section-image-grid-item {
  position: relative;
}

.section-image-grid-item-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.section-image-grid-item-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .section-image-grid-item-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }

  .section-image-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 100vh;
  }
}
