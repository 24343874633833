footer {
  background-color: black;
  padding: 32px 20px;
}

.social-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0 auto;
}

.social-links li {
  margin-right: 15px;
}

.social-links a {
  color: #333;
  font-size: 24px;
  transition: color 0.2s ease-in-out;
  display: flex;
  width: 100%;
}

.social-links img {
  width: 48px;
}

.social-links a:hover {
  color: #0077b5;
}
