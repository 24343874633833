#main-landing-page {
    margin-bottom:0px;
    margin-top:0px;
    border-top:0px;
}

.cursor-pointer {
    cursor:pointer;
}

#splash-main {
    width:100%;
    position:absolute;
    left:0%;
    top:9%;
    color:white;
    margin-top:25px;
    text-align:center;
}

.splash-logo {
    float:left;
    width:100%;
    text-align:center;
}

.splash-headline {
    float:left;
    width:100%;
    margin-top:50px;
    font-size:18px;
}

.splash-headline {
    float:left;
    width:100%;
    margin-top:50px;
    font-size:18px;
}

.splash-button-bullet-top {
    float:left;
    width:100%;
    margin-top:50px;
    font-size:12px;
}

.splash-button-bullet-bottom {
    float:left;
    width:100%;
    margin-top:-2px;
    font-size:12px;
}

.splash-main-button-wrapper {
    float:left;
    width:100%;
    margin-top:-9px;
    font-size:24px;
}

.splash-main-button {
    font-size:24px;
    padding-right:0px;
    padding-left:0px;
}

.splash-main-button-bracket {
    font-family:'Roboto';
    font-weight:100;
}

.splash-discord-top {
    position:fixed;
    right:30px;
    top:0px;
}

.splash-discord-button-inline {
    float:left;
    width:100%;
    font-size:14px;
    margin-top:30px;
    margin-bottom:30px;
}

.splash-mint-status-text {
    float:left;
    margin-left:10%;
    width:80%;
    font-size:14px;
    margin-top:60px;
}

.splash-modal-title-alt {
    margin-top:5px;
    display:none;
}

.splash-modal-confirmtxt {
    font-size:18px;
    margin-top:25px;
    display:none;
}

.splash-submodal-button {
    margin-top:-15px;
    font-size:12px;
    padding-right:0px;
    padding-left:0px;
}

.splash-modal-button {
    margin-top:-5px;
    font-size:24px;
    padding-right:0px;
    padding-left:0px;
}

.splash-modal-loader {
    margin-top:10px;
    display:none;
}
    
.terminal-base input{
    border:0px !important;
    border-color:black !important;
    padding-left:10px !important;
    margin-top:7px !important;
    height:auto !important;
    margin:0px !important;
}

.terminal-base input:focus{
    border:0px !important;
    border-color:black !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.sc-jTzLTM {
    display:none !important;
}

.sc-EHOje {
    overflow:hidden !important;
}

.iQSzyY {
    padding:0px !important;
}