html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --red-brand: rgb(255, 22, 22);
  --light-gray-text: #d0cfce;
}

@-moz-document url-prefix() { body { font-size: 100%; } } 

.cursor-pointer {
  cursor:pointer;
}

.headline-text {
  font-family:'EurostileExt','Helvetica','Arial';
}

.subheadline-text {
  font-family:'NBInternationalPro-Light','Helvetica','Arial';
}

.subheadline-text-mono {
  font-family:'NBInternationalPro-Mono','Helvetica','Arial';
}

.code-text {
  font-family:'NBInternationalPro-Mono','Helvetica','Arial';
}

.code-text2 {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Page Width */
.page-width {
  position:relative;
  margin-left:7.5%;
  margin-right:7.5%;
  width:85%;
}

.page-width-wide {
  position:relative;
  margin-left:0.5%;
  margin-right:0.5%;
  width:99%;
}

.page-main-body {
  margin-top:30px;
  padding-bottom:50px;
}

.page-clean-body {
  margin-top:0px;
  padding-bottom:0px;
}

.clean-row {
  position:relative;
  margin:0px;
  padding:0px;
}

/* Global Header */
.top-nav-header-wide {
  position:relative;
  font-size:18px;
  padding-top:15px;
  border-radius:10px;
  margin-bottom:0px;
}

.top-nav-header {
  position:relative;
  font-size:18px;
  margin-top:0px;
  border-radius:10px;
  margin-bottom:0px;
  z-index:1000;
}

.top-nav-header-full {
  position:relative;
  font-size:14px;
  padding-top:0px;
  border-radius:10px;
  padding-bottom:5px;
  margin-top:7px;
  margin-bottom:0px;
}

.top-nav-container {
  position:relative;
  font-size:18px;
  margin-left:10%;
  margin-right:10%;
  width:80%;
  margin-top:0px;
  border-radius:10px;
  padding:10px;
}

.top-nav-border {
  border:1px solid white;
  position:absolute;
  top:5px;
  left:0;
  z-index:-1;
  width:100%;
  height:50px;
  border-radius:5px;
  transform: skew(-20deg);
}

.top-nav-left-logo {
  float:left;
  width:50%;
  margin-top:7px;
  text-align:left;
}

.top-nav-left-logo-full {
  float:left;
  margin-top:7px;
  text-align:left;
  margin-left:10px;
  position:relative !important;
}

.top-nav-header-left-section {
  float:left;
  margin-top:15px;
  text-align:left;
}

.top-nav-header-left-item {
  float:left;
  height:35px;
  font-size:14px;
  margin-left:25px;
}

.top-nav-header-item-badge {
  float:left;
  margin-left:7px;
  border-radius:5px;
  font-size:10px;
  padding:1px 5px;
  height:18px;
  line-height:18px;
  margin-top:11px
}

.top-nav-header-right-section {
  float:left;
  width:50%;
  margin-top:15px;
  text-align:left;
}

.top-nav-header-right-section-full {
  float:left;
  margin-top:15px;
  text-align:left;
}

.top-nav-header-right-item {
  float:right;
  height:35px;
  font-size:18px;
  margin-left:25px;
}

.top-nav-header-right-item-full {
  float:right;
  height:35px;
  font-size:14px;
  margin-right:25px;
}

.top-nav-header-right-item-text {
  float:left;
  margin-top:7px;
  margin-left:5px;
}

.top-nav-header-right-item-icon {
  float:left;
  height:35px;
}

.top-nav-header-item-text {
  float:left;
  margin-top:10px;
  margin-left:5px;
}

.top-nav-header-item-icon {
  float:left;
  height:35px;
}

.top-nav-header-item-bubble {
  float:left;
  margin-top:16px;
  margin-left:5px;
  margin-right:5px;
}

.top-nav-header-right-profile-icon {
  float:left;
  height:37px;
  width:37px;
  border-radius:18.5px;
  overflow:hidden;
  position:relative;
  border:1px solid transparent;
}

.top-nav-left-button {
  text-align:left;
  padding-left:20px;
  margin-top:7px;
}

.top-nav-middle-text {
  text-align:center;
  margin-top:7px;
}

.top-nav-right-button {
  font-size:18px;
  padding:0px;
  margin-top:2px;
  text-align:left;
}

.top-nav-right-chevron {
  float:right;
  font-size:30px;
}

.top-nav-left-button {
  font-size:18px;
  padding:0px;
  margin-top:1px;
  text-align:left;
}

.top-nav-left-chevron {
  float:left;
  font-size:30px;
}

.top-nav-mobile-container {
  float:left;
  width:100%;
  margin-top:20px;
  text-align:center;
}

.top-nav-mobile-space {
  position:absolute;
  top:10;
  left:10;
  z-index:0;
}

.top-nav-mobile-middle-text {
  text-align:center;
  margin-top:5px;
}

/* Sub Navigation */
.border-section-full {
  position:relative;
  font-size:14px;
  border-radius:10px;
  padding:0px;
  margin:0px;
  margin-top:5px;
  margin-bottom:5px;
}

.divider-thin {
  height:1px;
  width:100%;
  background:white;
}

.divider-thick {
  height:2px;
  width:100%;
  background:white;
}

.corner-button {
  padding:10px;
  padding-top:12px;
  padding-bottom:8px;
  border:2px solid white;
  border-radius:30px;
}

.corner-button:hover {
  color:#FF0000;
  border-color:#FF0000;
  cursor:pointer;
}

.side-nav-button-section {
  width:100%;
  margin-top:0px;
  margin-bottom:0px;
  position:relative;
}

.side-nav-button {
  width:100%;
  margin-top:5px;
  border-radius:5px;
  height:50px;
  vertical-align:middle;
  background:#3A3A3A !important;
  color:white;
  font-family: 'NBInternationalPro-Light','Roboto', Helvetica !important;
}

.side-nav-button:hover {
  width:100%;
  margin-top:5px;
  height:50px;
  vertical-align:middle;
  background:white !important;
  color:black !important;
}

.side-nav-button-active {
  width:100%;
}

.side-nav-button-active .bubble-button{
  background:white !important;
  border-color:white;
}

.side-nav-button-active:hover .bubble-button{
  background:#3A3A3A !important;
  border-color:#3A3A3A;
}

.side-nav-button-text {
  margin-top:2px;
  font-size:14px;
  text-transform: none !important;
}

.side-nav-button-bubble {
  margin-top:14px;
  font-size:14px;
  text-transform: none !important;
}


.side-nav-button-red {
  width:100%;
  margin-top:5px;
  border-radius:5px;
  height:50px;
  vertical-align:middle;
  background:#FF0000 !important;
  color:white;
  font-family: 'NBInternationalPro-Light','Roboto', Helvetica !important;
}

.side-nav-button-red:hover {
  width:100%;
  margin-top:5px;
  height:50px;
  vertical-align:middle;
  background:white !important;
  color:black !important;
}

.side-nav-button-border {
  width:100%;
  margin-top:5px;
  border-radius:5px;
  height:40px;
  vertical-align:middle;
  border:1px solid #3A3A3A !important;
  color:white;
  background:transparent !important;
  font-family: 'NBInternationalPro-Light','Roboto', Helvetica !important;
}

.side-nav-button-border:hover {
  width:100%;
  margin-top:5px;
  height:40px;
  vertical-align:middle;
  background:white !important;
  color:black !important;
}

.round-border-button {
  border-radius:25px;
  color:white;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid white;
  cursor:pointer;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}

.round-border-button:hover {
  color:#FF0000;
  border:1px solid #FF0000;
}

.round-border-button-green {
  border-radius:25px;
  color:#AAE167;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid #AAE167;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}

.round-border-button-yellow {
  border-radius:25px;
  color:#FFF367;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid #FFF367;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}


.round-border-button-grey {
  border-radius:25px;
  color:#606060;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid #606060;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}

.round-border-button-whitepink {
  color:white !important;
}

.round-border-button-whitepink:hover {
  color:#e61f93 !important;
  border:1px solid #e61f93 !important;
}

.round-border-button-whitebaseblue {
  color:white !important;
}

.round-border-button-whitebaseblue:hover {
  color:#3773f5 !important;
  border:1px solid #3773f5 !important;
}



.round-border-button-whitegreen {
  color:white !important;
}

.round-border-button-whitegreen:hover {
  color:#AAE167 !important;
  border:1px solid #AAE167 !important;
}

.round-close-button {
  border-radius:14px;
  color:white;
  padding:5px 5px 7px 5px;
  line-height: 1.25 !important;
  border:1px solid white;
  cursor:pointer;
  width:auto;
  height:auto;
  font-size:14px;
  font-family:'EurostileExt','Helvetica','Arial';
  overflow:hidden;
  height: calc(1em + (1em/1.75));
  position:absolute;
  right:0px;
  top:-10px;
}

.round-close-button:hover {
  color:#FF0000;
  border:1px solid #FF0000;
}

.navtoggle {
  color:white;
  background:transparent;
  text-transform:none !important;
  font-family:'NBInternationalPro-Light','Helvetica','Arial' !important;
  padding:0px 10px !important;
  height:30px;
  line-height: 30px;
}

.navtogglefilled {
  color:white;
  background:#606060 !important;
  /* border:2px solid white; */
}

/* Section Titles - With Underlines */
.section-title-text {
  width:100%;
  font-size:18px;
  font-weight:900;
  color:white;
}

.section-title-text-medium {
  width:100%;
  font-size:16px;
  font-weight:900;
  color:white;
}

.section-title-underline {
  width:100%;
  border-bottom:2px solid white;
  height:10px;
}

.section-title-underline-cap {
  width:10%;
  height:10px;
  float:right;
  background:white;
}

/* Input Fields */
.grey-bubble-input {
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.grey-bubble-input:focus {
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.grey-bubble-input:active {
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.grey-bubble-input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  border: thin solid grey;
  border-radius: 3px;
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  background:#606060;
  height: auto !important;
  line-height: 1.5 !important;
  padding:5px 10px;
  cursor:pointer;
  margin-left:-10px;
  margin-right:10px;
}

.light-grey-bubble-input {
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.light-grey-bubble-input:focus {
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.light-grey-bubble-input:active {
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.light-grey-bubble-input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  border: thin solid grey;
  border-radius: 3px;
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  background:#606060;
  height: auto !important;
  line-height: 1.5 !important;
  padding:5px 10px;
  cursor:pointer;
  margin-left:-10px;
  margin-right:10px;
}


.white-bubble-input {
  border:1px solid white !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.white-bubble-input:focus {
  border:1px solid white !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.white-bubble-input:active {
  border:1px solid white !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.white-bubble-input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  border: thin solid white;
  border-radius: 3px;
  border:1px solid white !important;
  color:black !important;
  font-size:14px !important;
  background:white;
  height: auto !important;
  line-height: 1.5 !important;
  padding:5px 10px;
  cursor:pointer;
  margin-left:-10px;
  margin-right:10px;
}





.white-bubble-input-dark {
  border:1px solid white !important;
  color:white !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.1) !important;
}

.white-bubble-input-dark:focus {
  border:1px solid white !important;
  color:white !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(0,0,0,.5) !important;
  outline:none;
}

.white-bubble-input-dark:active {
  border:1px solid white !important;
  color:white !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(0,0,0,.5);
  outline:none;
}


.black-bubble-input-dark {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
}

.black-bubble-input-dark:focus {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.black-bubble-input-dark:active {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}

.black-bubble-input-round {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.black-bubble-input-round:focus {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.black-bubble-input-round:active {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}


.grey-bubble-input-round-small {
  border:1px solid #272727 !important;
  color:black !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.grey-bubble-input-round-small:focus {
  border:1px solid #272727 !important;
  color:black !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.grey-bubble-input-rounds-small:active {
  border:1px solid #272727 !important;
  color:black !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}

/* Chips Typehead */
.chips {
  color:white !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
  font-family:'NBInternationalPro-Light','Helvetica','Arial';
}
.chips:focus {
  border-bottom: 1px solid white !important;
  color:white !important;
}

.chips .input {
  color:white !important;
  font-family:'NBInternationalPro-Light','Helvetica','Arial';
}

.chips .dropdown-content {
  background: #e4e4e4 !important;
  color: black !important;
  margin-top:10px !important;
  top: 40px !important;
}

.autocomplete-content li .highlight {
  color:#3A3A3A !important;
}

.autocomplete-content li{
  color:black !important;
}

.chips .dropdown-content li>a {
  color:black !important;
}
.chips .dropdown-content li>span {
  color:black !important;
}

.chip:focus {
  background-color:#FF0000 !important;
  color:white !important;
}


/* Stats Bars */
.stat-bar-row {
  font-size:14px;
  margin-bottom:5px;
}

.stat-bar-top-text {
  text-align:right;
  font-size:16px;
}

.stat-bar-base-bar {
  border-radius:0px;
  height: 10px;
  margin-top:5px;
  border-bottom: 1px solid white !important;
}

/* Page Gridboxes */
.page-gridbox {
  width:100%;
  height:350px;
  background:black;
  border-radius:10px;
  position:relative;
  overflow:hidden;
}

.page-gridbox-header {
  position:absolute;
  top:7%;
  left:30px;
  font-size:18px;
}

.page-gridbox-info {
  position:absolute;
  top:40%;
  left:30px;
  font-size:18px;
}

.page-gridbox-inforow {
  float:left;
  width:100%;
  margin-bottom:5px;
}

/* Page Modals */
.page-modal {
  border-radius:10px !important;
  color:white !important;
  background-color: #3A3A3A !important;
}

.page-modal-right {
  left:unset !important;
  right:20px !important;
  top:20px !important;
}

.page-modal-small {
  max-width:500px !important;
}

.page-modal-smedium {
  max-width:575px !important;
}



.page-modal-medium {
  max-width:700px !important;
}

.page-modal-tall {
  max-height:90vh !important;
}

.page-modal-large {
  width:55% !important;
  max-height: 80vh !important;
}

.page-modal-media {
  width:55% !important;
  max-height: 80vh !important;
  line-height:0 !important;
  border:1px solid #3A3A3A;
  background:black !important;
}

.page-modal-media-vertical {
  max-width: 25% !important;
  width:auto !important;
  max-height: 80vh !important;
  line-height:0 !important;
  border:1px solid #3A3A3A;
  background:black !important;
}

.page-modal-full {
  max-width:100% !important;
  width:98% !important;
  max-height:90vh !important;
}

@media screen and (max-width: 750px) {
  .page-modal-smedium {
    max-width:100% !important;
    width:92% !important;
  }
  .nav-wrapper {
    height:auto !important;
  }
}

@media screen and (max-width: 950px) {
  .page-modal-large {
    max-width:100% !important;
    width:92% !important;
  }

  .page-modal-full {
    max-width:100% !important;
    width:92% !important;
  }

  .page-modal-media {
    max-width:100% !important;
    width:92% !important;
    border:1px solid #EBEBEB;
  }

  .page-modal-media-vertical {
    max-width: 80% !important;
    width:auto !important;
    max-height: 90vh !important;
    line-height:0 !important;
    border:1px solid #3A3A3A;
    background:black !important;
  }
}

/* Color Specific */
.btn-flat2 {
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:18px;
}

.exr-red-bg {
  background-color: #FF0000 !important;
}

.exr-dark-grey-bg {
  background-color: #141414 !important;
}

.exr-light-grey-bg {
  background-color: #d0cfce !important;
}

.exr-light-grey-text {
  color: #d0cfce;
}

.exr-red-text {
  color: #FF0000;
}

.exr-trans-grey-bg {
  background-color:rgb(20,20,20,0.8);
}

.exr-trans-black-bg {
  background-color:rgb(0,0,0,0.8);
}

.exr-trans-black-bg-subtle {
  background-color:rgb(0,0,0,0.25);
}

.exr-new-dark-grey {
  background-color:#272727;
}

.exr-new-light-grey {
  background-color:#3A3A3A;
}

.exr-stats-light-grey {
  background-color:#606060;
}

.exr-stats-light-grey2 {
  background-color:#7a7979;
}

.exr-stats-light-green {
  background-color:#B4DF77;
}

.exr-stats-red {
  background-color:#EB2228;
}

.exr-stats-light-green2 {
  background-color: #AAE167 !important;
}

.exr-light-green {
  background-color: #AAE167 !important;
}

.exr-light-green-text {
  color: #AAE167 !important;
}

.exr-stats-yellow {
  background-color:#FFF367 !important;
}

.exr-yellow-text {
  color:#FFF367 !important;
}

/* Track Meet Racing Accents */

.exr-orange {
  background-color: #EF5C28 !important;
}

.exr-light-orange {
  background-color: #ED752A !important;
}

.exr-orange-text {
  color: #EF5C28 !important;
}

.exr-light-orange-text {
  color: #ED752A !important;
}

/* PINK Racing Accents */

.exr-dark-pink {
  background-color:#e61f93 !important;
}

.exr-dark-pink-text {
  color:#e61f93 !important;
}

.exr-mid-pink {
  color:#fe0595 !important;
}

.exr-mid-pink-text {
  color:#fe0595 !important;
}

.pink-dark-pink-background {
  background-color:#e61f93 !important;
}

.pink-mid-pink-background {
  color:#fe0595 !important;
}

.pink-dark-pink-text {
  color:#e61f93 !important;
}

.pink-mid-pink-text {
  color:#fe0595 !important;
}

.exr-pink-text {
  color:hsl(325, 100%, 56%) !important;
}

.pink-pink-background-link {
  background-color:#e61f93 !important;
  cursor:pointer;
}

.pink-pink-background-link:hover {
  background-color:#e61f93 !important;
}

.pink-grey-background-link {
  background-color:#3A3A3A !important;
  cursor:pointer;
}

.pink-grey-background-link:hover {
  background-color:#e61f93 !important;
}

/* BASE Racing Accents */

.exr-base-blue {
  background-color:#3773f5 !important;
}

.exr-base-blue-text {
  color:#3773f5 !important;
}

.exr-base-blue-light-text {
  color:#37AEF5;
}

.base-blue-background {
  background-color:#3773f5 !important;
}

.base-blue-background-link {
  background-color:#3773f5 !important;
  cursor:pointer;
}

.base-blue-background-link:hover {
  background-color:#3773f5 !important;
}

.base-blue-base-blue-background-link {
  background-color:#3773f5 !important;
  cursor:pointer;
}

.base-blue-base-blue-background-link:hover {
  background-color:#3773f5 !important;
}

.base-blue-grey-background-link {
  background-color:#3A3A3A !important;
  cursor:pointer;
}

.base-blue-grey-background-link:hover {
  background-color:#3773f5 !important;
}

/* LightLink Racing Accents */
.lightlink-light-grey-background {
  background: #f8f9fa !important;;
}

.lightlink-cyan-text {
  color: #69FFFC;
}

.lightlink-cyan-background {
  background: #69FFFC !important;;
}

.lightlink-purple-text {
  color: #6A81FF;
}

.lightlink-purple-background {
  background: #6A81FF !important;;
}

.lightlink-gradient-background {
  background: linear-gradient(90deg, #68FFFC, #5C8FFF) !important;
}

.lightlink-cyan-link {
  color: #69FFFC !important;
}

.lightlink-cyan-link:hover {
  color: #69FFFC !important;
}

.lightlink-cyan-link-active {
  color: #69FFFC !important;
  cursor:pointer;
}

.lightlink-purple-link {
  color: #6A81FF !important;
}

.lightlink-purple-link:hover {
  color: #6A81FF !important;
}

.lightlink-purple-link-active {
  color: #6A81FF !important;
  cursor:pointer;
}

.white-lightlink-link {
  color: white !important;
}

.white-lightlink-link:hover {
  color: #69FFFC !important;
}

.white-lightlink-link-active {
  color: #69FFFC !important;
  cursor:pointer;
}

.round-border-button-whitelightlink {
  color:white !important;
}

.round-border-button-whitelightlink:hover {
  border:1px solid #68FFFC;
  background: linear-gradient(90deg, #68FFFC, #5C8FFF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grey-lightlink-link {
  color:#606060 !important;
  cursor:pointer;
}
.grey-lightlink-link:hover {
  color: #69FFFC !important;
}

.grey-lightlink-link-active {
  color: #69FFFC !important;
  cursor:pointer;
}



.exr-dark-grey-trans {
  background: rgb(0,0,0,.5);
}

.exr-scroll-bar-vertical {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: rgb(0,0,0,.75) rgb(0,0,0,.2);
  scrollbar-width: thin;
}

.exr-scroll-bar-vertical::-webkit-scrollbar {
  background-color: rgb(0,0,0,.2);
}

/* Add a thumb */
.exr-scroll-bar-vertical::-webkit-scrollbar-thumb {
  background-color: rgb(0,0,0);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #606060;
  border-radius:5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EBEBEB;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Buttons */
.bracket-button {
  font-size:20px;
  padding-right:0px;
  padding-left:0px;
}

.bracket-button-bracket {
  font-family:'Roboto';
  font-weight:100;
  padding:0px 3px 0px 3px;
}

.bubble-button {
  width:8px;
  height:8px;
  border-radius:8px;
  border:1px solid white;
}

.white-fill-link {
  cursor:pointer;
}

.white-fill-link .bubble-button {
  background:transparent;
}

.white-fill-link:hover .bubble-button {
  background:white;
}

.white-fill-link-filled .bubble-button {
  background:white !important;
}

.grey-fill-link {
  cursor:pointer;
}

.grey-fill-link .bubble-button {
  background:transparent;
  border-color:white;
}

.grey-fill-link:hover .bubble-button {
  background:#3A3A3A;
  border-color:#3A3A3A;
}

.black-red-link {
  color:black;
}

.black-red-link:hover {
  color: #FF0000;
}

.white-red-link {
  color:white !important;
  cursor:pointer;
}

.white-red-link:hover {
  color: #FF0000 !important;
}

.white-red-link-active {
  color: #FF0000 !important;
  cursor:pointer;
}

.white-green-link {
  color:white !important;
  cursor:pointer;
}

.white-green-link:hover {
  color: #B4DF77 !important;
}

.white-green-link-active {
  color: #B4DF77 !important;
  cursor:pointer;
}

.white-yellow-link {
  color:white !important;
  cursor:pointer;
}

.white-yellow-link:hover {
  color: #FFF367 !important;
}

.white-yellow-link-active {
  color: #FFF367 !important;
  cursor:pointer;
}

.yellow-yellow-link {
  color: #FFF367 !important;
  cursor:pointer;
}

.yellow-yellow-link:hover {
  color: #FFF367 !important;
}

.yellow-yellow-link-active {
  color: #FFF367 !important;
  cursor:pointer;
}

.grey-green-link {
  color:#606060 !important;
  cursor:pointer;
}

.grey-green-link:hover {
  color: #B4DF77 !important;
}

.grey-green-link-active {
  color: #B4DF77 !important;
  cursor:pointer;
}

.green-green-link {
  color: #B4DF77 !important;
}

.green-green-link:hover {
  color: #B4DF77 !important;
}

.green-green-link-active {
  color: #B4DF77 !important;
  cursor:pointer;
}

.white-pink-link {
  color: white !important;
}

.white-pink-link:hover {
  color: #e61f93 !important;
}

.white-pink-link-active {
  color: #e61f93 !important;
  cursor:pointer;
}

.pink-pink-link {
  color: #e61f93 !important;
}

.pink-pink-link:hover {
  color: #e61f93 !important;
}

.pink-pink-link-active {
  color: #e61f93 !important;
  cursor:pointer;
}

.white-base-blue-link {
  color: white !important;
}

.white-base-blue-link:hover {
  color: #3773f5 !important;
}

.white-base-blue-link-active {
  color: #3773f5 !important;
  cursor:pointer;
}

.base-blue-blue-link {
  color: #3773f5 !important;
}

.base-blue-blue-link:hover {
  color: #3773f5 !important;
}

.base-blue-blue-link-active {
  color: #3773f5 !important;
  cursor:pointer;
}

.white-pink-border-link {
  color: white !important;
  border:1px solid #e61f93 !important;
}

.white-green-border-link {
  color: white !important;
  border:1px solid #B4DF77 !important;
}

.white-base-blue-border-link {
  color: white !important;
  border:1px solid #3773f5 !important;
}

.white-red-button {
  background-color:white !important;
  color:black !important;
}

.white-red-button:hover {
  background-color: #FF0000 !important;
  color: white !important;
}

.stats-light-grey-white-link {
  cursor:pointer;
  background-color:#606060 !important;
  color:white !important;
}

.stats-light-grey-white-link:hover {
  cursor:pointer;
  background-color: white !important;
  color: black !important;
}

.stats-light-grey-red-link {
  cursor:pointer;
  background-color:#606060 !important;
  color:white !important;
}

.stats-light-grey-red-link:hover {
  cursor:pointer;
  background-color: #FF0000 !important;
  color: white !important;
}

.stats-trans-grey-link {
  cursor:pointer;
  background-color: transparent !important;
  color:white !important;
}

.stats-trans-grey-link:hover {
  cursor:pointer;
  background-color: #606060  !important;
  color: white !important;
}


.grey-light-grey-button-link {
  background:#3A3A3A;
  border:1px solid #3A3A3A;
  cursor:pointer;
}

.grey-light-grey-button-link:hover {
  background:#606060;
  border:1px solid white;
}

.grey-light-grey-button-link-active {
  background:#606060;
  border:1px solid white;
}

.light-grey-stats-grey-button-link {
  background:#606060;
  border:1px solid #606060;
  cursor:pointer;
}

.light-grey-stats-grey-button-link:hover {
  background:#7a7979;
  border:1px solid white;
}

.light-grey-stats-grey-button-link-active {
  background:#7a7979;
  border:1px solid white;
}

.light-grey-green-button-link {
  background:transparent !important;
  border:1px solid  white;
  cursor:pointer;
}

.light-grey-green-button-link:hover {
  background:transparent !important;
  border:1px solid #B4DF77 !important;
  color:#B4DF77 !important;
}

.light-grey-green-button-link-active {
  background:transparent !important;
  border:1px solid #B4DF77 !important;
  color:#B4DF77 !important;
}



.white-pink-button-link {
  background:transparent !important;
  border:1px solid  white;
  cursor:pointer;
}

.white-pink-button-link:hover {
  background:transparent !important;
  border:1px solid #e61f93 !important;
  color:#e61f93 !important;
}

.white-pink-button-link-active {
  background:transparent !important;
  border:1px solid #e61f93 !important;
  color:#e61f93 !important;
}

.white-base-blue-button-link {
  background:transparent !important;
  border:1px solid  white;
  cursor:pointer;
}

.white-base-blue-button-link:hover {
  background:transparent !important;
  border:1px solid #3773f5 !important;
  color:#3773f5 !important;
}

.white-base-blue-button-link-active {
  background:transparent !important;
  border:1px solid #3773f5 !important;
  color:#3773f5 !important;
}

/* Tooltip Styling */
.material-tooltip {
  border-radius:10px !important;
  background:#3A3A3A;
  -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

/* Image Specific */
.fill-image-container {
  width:100%;
  height:100%;
}

/* Design Specific */
.cornercutout {
  position:absolute;
  background:black;
  width:10px;
  height:10px;
}

.top-left {
  top:0px;
  left:0px;
}

.top-right {
  top:0px;
  right:0px;
}

.bottom-left {
  bottom:0px;
  left:0px;
}

.bottom-right {
  bottom:0px;
  right:0px;
}

@import "~react-image-gallery/styles/css/image-gallery.css";